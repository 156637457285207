<template>
    <div class="index">
        
        <div class="top">
            <!-- <img src="../../assets/img/sqt_logo.png" alt="" class="top_title" /> -->
            <h1>{{ config.xm_title }}</h1>
            <p>{{ config.xm_desc }}</p>
        </div>
        <div class="card">
            <div class="tabs">
                <div class="tab" v-for="(item, index) in xiangmu" :class="{ active_class: xmselect == index }" @click="changeActive(index)">{{ index }}</div>
            </div>
            <van-cell-group inset>
                <van-field v-model="info.company_name" label="执照名称" placeholder="请输入执照名称" />
                <van-field v-model="info.company_code" class="height_field" label="统一社会信用代码" placeholder="请输入统一社会信用代码" />
                <van-field v-model="info.company_faren" label="法人姓名" placeholder="请输入法人姓名" />
                <van-field v-if="active == xmselect" v-model="info.id_card" label="身份证号" placeholder="请输入身份证号" />
                <van-field v-model="info.phone" label="手机号码" placeholder="请输入手机号码" />
                <van-field v-if="active == xmselect" v-model="info.turnover" label="年营业额" placeholder="请输入年营业额">
                    <template #button>
                        <div>(万)</div>
                    </template>
                </van-field>
                <van-field v-if="active == xmselect" v-model="info.num" label="员工人数" placeholder="请输入员工人数">
                    <template #button>
                        <div>(人)</div>
                    </template>
                </van-field>
                <div v-if="active != xmselect" class="subject">
                    <div class="subject_label">申报主体</div>
                    <van-radio-group v-model="sbzt" direction="horizontal" shape="dot">
                        <van-radio name="个体户" icon-size="16px">个体户</van-radio>
                        <van-radio name="公司" icon-size="16px">公司</van-radio>
                        <van-radio name="合作社" icon-size="16px">合作社</van-radio>
                    </van-radio-group>
                </div>
                <van-field v-if="active != xmselect" label="服务类型" :placeholder="xmselect + '（注:需电子执照授权）'" readonly />
            </van-cell-group>

            <div class="agreement flex">
                <van-checkbox v-model="checked_agree" icon-size="16px" shape="square"></van-checkbox>
                <div class="agree_desc">
                    <span>同意</span><span @click="show_business = true" class="agree">《工商代办协议》</span><span>和</span
                    ><span @click="show_privacy = true" class="agree">《隐私政策》</span>
                </div>

                <van-popup v-model:show="show_business" closeable class="popup_box">
                    <div class="popup_title">工商代办协议</div>
                    <div class="popup_content">
                        <p v-html="config.xieyi"></p>
                    </div>
                    <van-button @click="show_business = false" class="popup_bottom">同意</van-button>
                </van-popup>
                <van-popup v-model:show="show_privacy" closeable class="popup_box">
                    <div class="popup_title">隐私政策</div>
                    <div class="popup_content">
                        <p v-html="config.yinsi"></p>
                    </div>
                    <van-button @click="show_privacy = false" class="popup_bottom">同意</van-button>
                </van-popup>
            </div>
            <van-button @click="toOffer" class="apply_for">立即申报</van-button>
            <div class="tip">温馨提示：本服务为第三方代办服务，并非工商官网</div>
        </div>
        <div class="card">
            <div class="red_title">经营异常最高可被罚款 <img class="num_img" src="../../assets/img/num_img.png" alt="" />元</div>
            <div class="red_card">
                <div class="red_card_title">
                    <van-icon name="warning" color="#e5594a" size="22px" />
                    《市场主体登记管理条例实施细则》
                </div>
                <div class="main_title">第七十条明确规定</div>
                <div class="main_span">
                    市场主体未按照法律、行政法规规定的期限公示或者报送年度报告的，<span>由登记机关列入经营异常名录，可以处1万元以下罚款。</span>
                </div>
            </div>
            <div class="red_card">
                2024年新规，企业两年未报送年报将会吊销营业执照列入严重违法失信名单。您也可通过线下所属市监局或线上国家企业信用信息公示系统免费办理
            </div>
        </div>
        <div class="card">
            <div class="red_title">企业长期未经营不注销后果</div>
            <div class="consequence">
                <div class="consequence_box">
                    <img class="consequence_icon" src="../../assets/img/consequence_icon1.png" alt="" />
                    <div class="consequence_desc">列入经营异常状态</div>
                </div>
                <div class="consequence_box">
                    <img src="../../assets/img/consequence_icon2.png" alt="" class="consequence_icon" />
                    <div class="consequence_desc">遭受工商行政处罚</div>
                </div>
                <div class="consequence_box">
                    <img src="../../assets/img/consequence_icon3.png" alt="" class="consequence_icon" />
                    <div class="consequence_desc">银行贷款受阻</div>
                </div>
            </div>
        </div>
        <div class="card service">
            <van-icon name="service-o" class="service_icon" size="40px" />
            <div class="service_ri">
                <div class="phone">
                    在线客服<span>{{ config.kefu_phone }}</span>
                </div>
                <div class="short_link">查询年报进度、注销等业务，请联系客服</div>
            </div>
        </div>
        <div class="card introduce">
            <div class="introduce_title"><span>办理说明</span></div>
            <div class="introduce_desc" v-html="config.shuoming"></div>
        </div>
        <footer>
            <div>联系我们：{{ config.kefu_phone }}</div>
            <div>{{ config.xm_name }}</div>
        </footer>
        <div class="kefudianhua">
            <div @click="openNew" class="item">
                <van-icon name="service-o" color="#1296db" size="26px" />
                <div style="margin-top: 3px">微信客服</div>
            </div>
            <div @click="$router.push({ path: '/order' })" class="item">
                <van-icon name="search" color="#1296db" size="26px" />
                <div style="margin-top: 3px">查看订单</div>
            </div>
        </div>
        <van-popup v-model:show="show_pay" round closeable position="bottom" :style="{ height: '90%' }">
            <div class="paybox">
                <div class="card step">
                    <div class="introduce_title"><span>服务流程</span></div>
                    <img src="../../assets/img/step.png" alt="" class="step_img" />
                    <div class="step_item">
                        <span>内容填写</span>
                        <span>缴纳费用</span>
                        <span>服务代办</span>
                    </div>
                    <div class="info">
                        收费项目：<span>{{ xmselect }}</span>
                    </div>
                    <div class="info">
                        收费标准：<span>￥{{ price }}</span>
                    </div>
                    <div class="payway">
                        <div class="wechat">
                            <div class="flex">
                                <van-icon name="wechat-pay" size="24px" color="#43C93E" />
                                <div class="wechat_desc">微信支付</div>
                            </div>
                            <van-radio-group v-model="checked_paytype">
                                <van-radio name="wechat"></van-radio>
                            </van-radio-group>
                        </div>
                        <div class="wechat">
                            <div class="flex">
                                <van-icon name="alipay" size="24px" color="#007aff" />
                                <div class="wechat_desc">支付宝</div>
                            </div>
                            <van-radio-group v-model="checked_paytype">
                                <van-radio name="alipay"></van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div ref="pay_box"></div>
                </div>
                <div class="card introduce" style="margin-bottom: 60px">
                    <div class="introduce_title"><span>特别提醒</span></div>
                    <div class="introduce_desc" v-html="config.tixing"></div>
                </div>
                <div class="btn_box">
                    <van-button :loading="loading_btn" class="pay_button" @click="pay">立即支付</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    name: "index",
    data() {
        return {
            active: "", //年报报送1，异常移除2，申请注销3
            xmselect: "", //年报报送1，异常移除2，申请注销3
            info: {
                company_name: "",
                company_code: "",
                company_faren: "",
                id_card: "",
                phone: "",
                turnover: "",
                num: "",
            },
            sbzt: "公司",
            checked_agree: true,
            show_business: false,
            show_privacy: false,
            show_pay: false,
            config: {},
            price: 0,
            xiangmu: {},
            checked_paytype: "wechat",
            loading_btn: false,
        };
    },
    mounted() {
        this.id = this.$route.params.id !== undefined ? this.$route.params.id : "";
        this.getInfo();
    },
    methods: {
        //切换active
        changeActive(index) {
            this.xmselect = index;
            this.price = this.xiangmu[index];
        },
        //获取详情
        getInfo() {
            let params = {
                id: this.id,
            };
            this.$post("/api/index/info", params).then((res) => {
                if (res.code) {
                    this.xmselect = res.data.active;
                    this.active = res.data.active;
                    this.config = res.data.config;
                    this.xiangmu = res.data.xiangmu;
                    this.info = res.data.info;
                    this.price = this.xiangmu[this.active];
                } else {
                    // this.$showFailToast(res.msg);
                }
                // let xiangmu = Object.keys(res.data.xiangmu);
                // console.log(xiangmu);
            });
        },
        //微信客服
        openNew() {
            window.location.href = this.config.kefu_url;
        },
        //立即申报
        toOffer() {
            if (this.checked_agree == false) {
                this.$showFailToast("请先勾选工商代办协议及隐私政策");
            } else if (this.active == this.xmselect && this.info.company_name == "") {
                this.$showFailToast("执照名称不能为空");
            } else if (this.active == this.xmselect && this.info.company_code == "") {
                this.$showFailToast("统一社会信用代码不能为空");
            } else if (this.active == this.xmselect && this.info.company_faren == "") {
                this.$showFailToast("法人姓名不能为空");
            } else if (this.active == this.xmselect && this.info.phone == "") {
                this.$showFailToast("手机号码不能为空");
            } else if (this.active == this.xmselect && this.info.id_card == undefined) {
                this.$showFailToast("身份证号不能为空");
            } else {
                this.show_pay = true;
            }
        },
        //立即支付
        pay() {
            this.loading_btn = true;
            if (this.info.id_card == undefined) {
                this.info.id_card = "";
            }
            this.info.sbzt = this.sbzt;
            let params = {
                id: this.id,
                info: this.info,
                type: this.xmselect,
                paytype: this.checked_paytype,
            };

            this.$post("/api/index/addinfo", params)
                .then((res) => {
                    if (res.code == 1) {
                        
                        this.$showSuccessToast(res.msg);
                        setTimeout(() => {
                            this.loading_btn = false;
                            this.show_pay = false;
                            // window.open(res.data);

                            //支付宝支付返回表单，需要把表单单独提交，微信是直接返回网页跳转，所以不用执行提交，执行提交找不到表单 报错
                            if (this.checked_paytype == "alipay") {
                                this.$refs.pay_box.innerHTML = res.data;
                                this.$nextTick(() => {
                                    this.$refs.pay_box.children[0].submit();
                                });
                            } else {
                                window.location.href = res.data;
                            }
                        }, 500);
                    }else{
                        
                        this.loading_btn = false;
                        this.$showToast(res.msg);
                    }
                })
                .catch((err) => {
                    this.$showFailToast("提交异常，请稍后再试");
                    this.loading_btn = false;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.top {
    width: 375px;
    height: 193px;
    background-image: url("../../assets/img/top_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 60px;
    h1{
        color: #123fd5;
        font-size: 30px;
        text-align: center;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        margin: 20px 0 10px;
        text-shadow: #123fd5 0px 0px 1px;
    }
    p{
        color: #4b74f5;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        
        text-shadow: #4b74f5 0px 0px 1px;
    }
    .top_title {
        width: 320px;
        display: block;
        margin: auto;
    }
}
.card {
    width: 351px;
    background: #fff;
    margin: auto;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 15px;
    .tabs {
        display: flex;
        justify-content: space-evenly;
        .tab {
            width: 99px;
            height: 60px;
            font-weight: 700;
            line-height: 56px;
            text-align: center;
            color: #3763f1;
            background-image: url("../../assets/img/btn_bg.png");
            background-size: 100% 100% !important;
            background-repeat: no-repeat;
        }
        .active_class {
            color: #fff;
            background-image: url("../../assets/img/btn_bg_active.png");
        }
    }
}
.van-cell-group--inset {
    margin: 0;
    margin-top: 10px;
}
.van-cell {
    width: 330px;
    height: 57px;
    background-color: #f7f7f7;
    padding: 15px;
    margin-top: 10px;
    border-radius: 8px !important;
    line-height: 26px;
}
.van-cell:after {
    display: none;
}
::v-deep .van-field__label {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    width: 74px;
}
::v-deep .van-field__body {
    font-size: 15px;
}
.height_field {
    height: 72px;
    ::v-deep .van-field__label {
        line-height: 22px;
    }
    ::v-deep .van-cell__value {
        line-height: 47px;
    }
}
.subject {
    width: 330px;
    height: 57px;
    border: 8px;
    background-color: #f7f7f7;
    margin-top: 10px;
    padding: 15px;
    box-sizing: border-box;
    line-height: 27px;
    display: flex;
    .subject_label {
        font-size: 16px;
        font-weight: 700;
        width: 74px;
    }
    .van-radio-group {
        margin-left: 10px;
    }
    .van-radio {
        margin-right: 10px;
    }
    ::v-deep .van-radio__label {
        margin-left: 3px !important;
    }
}
.agreement {
    width: 298px;
    margin: 15px 10px;
    .agree_desc {
        margin-left: 5px;
        font-size: 14px;
    }
    .agree {
        color: rgb(55, 99, 241);
    }
}
.apply_for {
    width: 330px;
    height: 45px;
    margin-top: 8px;
    background: #3763f1;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
}
.tip {
    text-align: center;
    color: #7b7b7d;
    font-size: 13px;
    margin-top: 8px;
}
.popup_box {
    width: 302px;
    .popup_title {
        font-size: 18px;
        color: #303133;
        padding: 15px 15px 10px;
    }
    .popup_content {
        height: 320px;
        color: #606266;
        font-size: 14px;
        padding: 10px 15px;
        overflow: auto;
        line-height: 24px;
    }
    .popup_bottom {
        width: 56px;
        height: 32px;
        color: #fff;
        font-size: 12px;
        background-color: #409eff;
        float: right;
        margin: 15px;
        border: none;
    }
}
.red_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #e5695d;
    text-align: center;
}
.num_img {
    width: 80px;
    margin: 0 8px;
}
.red_card {
    margin-top: 10px;
    padding: 10px;
    background: #fef9f9;
    border-radius: 8px;
    .warn_icon {
        width: 20px;
    }
    .red_card_title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        font-weight: 600;
        color: #39393b;
    }
    .main_title {
        text-align: center;
        margin: 10px 0;
    }
    .main_span {
        margin: 10px 0;
    }
    .main_span span {
        font-weight: 600;
        color: #dd2713;
    }
}
.consequence {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    .consequence_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 0 3px 10px;
        background: #fef9f9;
        border-radius: 5px;
    }
    .consequence_icon {
        width: 50px;
        height: 50px;
        margin: 10px auto 0;
    }
    .consequence_desc {
        margin: 10px 0;
        font-size: 13px;
        color: #39393b;
        text-align: center;
    }
}
.service {
    display: flex;
    align-items: center;
    .service_icon {
        box-sizing: border-box;
        background-color: #3863f2;
        padding: 9px;
        color: #fff;
        border-radius: 10px;
        margin-right: 8px;
    }

    .phone span {
        font-size: 13px;
        color: #7b7b7d;
        margin-left: 5px;
    }
    .short_link {
        margin-top: 5px;
        font-size: 13px;
        color: #3763f1;
    }
}
.introduce {
    .introduce_title {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .introduce_title:before {
        display: block;
        width: 98px;
        height: 16px;
        content: "";
        background-image: url("../../assets/img/title_icon1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .introduce_title:after {
        transform: rotate(180deg);
        display: block;
        width: 98px;
        height: 16px;
        content: "";
        background-image: url("../../assets/img/title_icon1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .introduce_title span {
        margin: 0 8px;
        font-family: SourceHanSansSC;
        font-size: 17px;
        font-weight: 700;
        background: linear-gradient(60deg, #2959f5, #8da7fd);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .introduce_desc {
        margin-top: 15px;
        color: #39393b;
        font-size: 14px;
        line-height: 24px;
        ol,
        li {
            list-style: decimal;
        }
    }
}

footer {
    margin-top: 30px;
    padding-bottom: 20px;
    line-height: 30px;
    font-size: 14px;
    color: #7b7b7d;
    text-align: center;
}
ul {
    list-style: disc !important;
}
.kefudianhua {
    box-sizing: border-box;
    width: 60px;
    position: fixed;
    right: 0px;
    bottom: 60px;
    background: #f0f0f0;
    border-radius: 8px 0 0 8px;
    z-index: 9;
    text-align: center;
    color: #000;
    font-size: 10px;
    text-decoration: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    .item {
        width: 100%;
        padding: 6px 0;
        div {
            margin-top: -3px;
        }
    }
    .item:first-child {
        border-bottom: solid 1px #ddd;
    }
    img {
        margin: 3px 0;
        width: 20px;
    }
}
.paybox {
    margin-bottom: 20px;
    .card {
        width: 351px;
        background: #fff;
        margin: auto;
        border-radius: 10px;
        padding: 12px;
        margin-top: 15px;
    }
    .introduce_title {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .introduce_title:before {
        display: block;
        width: 98px;
        height: 16px;
        content: "";
        background-image: url("../../assets/img/title_icon1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .introduce_title:after {
        transform: rotate(180deg);
        display: block;
        width: 98px;
        height: 16px;
        content: "";
        background-image: url("../../assets/img/title_icon1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .introduce_title span {
        margin: 0 8px;
        font-family: SourceHanSansSC;
        font-size: 17px;
        font-weight: 700;
        background: linear-gradient(60deg, #2959f5, #8da7fd);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .introduce_desc p {
        margin-top: 10px;
        font-size: 16px;
    }
    .red_span {
        color: rgb(255, 109, 53);
    }
    .step {
        margin-top: 10px;
        width: 351px;
        margin-right: auto;
        margin-left: auto;
        background-color: transparent !important;
        background-image: radial-gradient(circle at 10px 10px, transparent 0, transparent 10px, #fff 0, #fff 100%) !important;
        background-position: -10px 240px !important;
        border-radius: 10px;
        .step_img {
            width: 330px;
        }
        .step_item {
            display: flex;
            justify-content: space-between;
            margin-top: -5px;
            font-size: 16px;
        }
        .step_item span {
            width: 20vw;
            text-align: center;
        }
        .info {
            margin-top: 10px;
        }
        .wechat {
            width: 330px;
            height: 51px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            background: #f7f7f7;
            padding: 15px;
            justify-content: space-between;
            margin-top: 10px;
        }
        .wechat_img {
            width: 20px;
            margin-right: 5px;
        }
        .check {
            width: 15px;
        }
    }
    .btn_box {
        width: 375px;
        background: #fff;
        height: 56px;
        position: fixed;
        bottom: 0;
        left: 0px;
    }
    .pay_button {
        margin-top: 8px;
        background: #3763f1;
        width: 100%;
        color: #fff;
        border-radius: 5px;
        padding: 12px 0;
        text-align: center;
        margin-top: 6px;
    }
}
.wechat_desc {
    line-height: 24px;
    margin-left: 2px;
}
</style>
